// const about = [
//     "Greetings! As an enthusiastic 4th year Computer Science and Engineering student at Vellore Institute of Technology, I'm on a quest to conquer the realm of technology and innovation. Armed with dedication and skill, I have successfully crafted ingenious solutions to real-world problems. My expertise lies in the magical realm of the MERN stack and the fascinating world of Android App development.",
//     "When it comes to coding, I'm fluent in the languages of JavaScript, Java, and C++. These tools are my trusty companions in the digital wilderness, empowering me to bring ideas to life. But that's not all! I'm also an explorer of cutting-edge technologies, venturing into the realms of Machine Learning, Artificial Intelligence, Blockchain, and Game Technology. My insatiable curiosity drives me to stay on the bleeding edge of tech innovation.",
//     "But it's not just about solo adventures! I thrive in the vibrant community of creators and dreamers. Hackathons are my battlegrounds, where I put my skills to the test and emerge victorious. I have a knack for turning ideas into reality and have been fortunate enough to hoist the trophy in several competitions. I'm always eager to collaborate on exciting projects and engage with communities that foster growth in the ever-evolving world of technology.",
//     "If you have any questions or wish to discuss projects or ideas within my realm of expertise, don't hesitate to reach out! I'm here to help and share my knowledge. Together, we can achieve great things and make a meaningful impact.",
//     // "For an immersive journey through my skills, projects, and experience, feel free to explore my online portfolio at prasoonsoni.com. 🌐 There, you'll find a treasure trove of my work, providing a comprehensive glimpse into my technical prowess. It's not just a collection of achievements; it's a window into my passion for technology and the milestones I've reached. Take a look and let's embark on an exciting adventure together! 🚀🌟"
// ];

const about = [
    "Hey there! I’m Prasoon Soni, a tech enthusiast, innovator, and problem solver passionate about creating digital solutions that leave a lasting impact. My journey in the world of technology has been an exciting adventure, driven by curiosity, creativity, and a desire to turn ideas into reality.",
    "As a Computer Science and Engineering Grad at Vellore Institute of Technology, I’ve built a strong foundation in software development and problem-solving. Over the years, I’ve honed my skills in JavaScript, Java, and C++, mastering technologies like the MERN stack and Android app development. But my love for tech doesn’t stop there! I’m also an avid explorer of Machine Learning, Artificial Intelligence, Blockchain, and Game Technology, constantly staying ahead in the fast-evolving tech landscape.",
    "Currently, I’m driving innovation as a Software Engineer at Wells Fargo, where I’ve developed powerful tools to streamline release management, reduce manual processes, and optimize workflows. Previously, at AI.HYR, I revolutionized recruitment automation, and at Samsung R&D, I delved into conversational AI to build intuitive voice-based experiences. My stints at Accenture and Spark Consultancy gave me deep insights into cloud security, scalable architecture, and web development.",
    "What sets me apart is my love for collaboration and competition. Hackathons are my playgrounds, and I’ve been fortunate to win numerous awards by building solutions that tackle real-world problems creatively. From creating a decentralized data protection vault to developing Android apps with thousands of downloads, I’ve always aimed to merge innovation with usability.",
    "But it’s not just about writing code — it’s about making a difference. My work has earned recognition at prestigious competitions and led to several publications in fields like AI, blockchain, and cybersecurity. I also enjoy mentoring budding developers, sharing knowledge at seminars, and brainstorming new ways technology can enhance everyday life.",
    "If you’re as passionate about technology and innovation as I am, let’s connect! Whether it’s brainstorming new ideas, collaborating on impactful projects, or simply exchanging knowledge, I’m always excited to engage with fellow dreamers and doers. Together, we can create, innovate, and shape a better future! 🚀"
];


export default about