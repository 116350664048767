import { Text } from "@chakra-ui/react"
import { SiReact, SiNodedotjs, SiTypescript } from "react-icons/si"
export const projects = [
    {
        id: 12,
        title: "#GithubWrapped",
        subtitle: "Explore your contributions, top languages, and more from your year in code!",
        link:"https://gw.prasoonsoni.com",
        repo:"",
        emoji:"🧑🏻‍💻"
    },
    {
        id: 12,
        title: "FortiSafe",
        subtitle: "Safeguarding resources with role and group based access control systems.",
        link:"https://documenter.getpostman.com/view/28558819/2s946mZ9Ld",
        repo:"https://github.com/prasoonsoni/FortiSafe",
        emoji:"⚔️"
    },
    {
        id: 8,
        title: "ResearcHaven",
        subtitle: "A web application for filtering and processing research proposals for funding.",
        link: "https://webcrawlers.tech",
        repo: "https://github.com/prasoonsoni/ResearcHaven-SIH-BACKEND",
        emoji: "📝"
    },
    {
        id: 0,
        title: "URL Shortner",
        subtitle: "Having long URLs ??? No Problem, Shorten them now.",
        link: "https://urlshortner.bitwiz.me/",
        repo: "https://github.com/prasoonsoni/URL-Shortner",
        emoji: "🔗"
    },
    {
        id: 1,
        title: "Wanna Say Something??",
        subtitle: "Send and Receive anonymous messages, opinions, confessions etc.",
        link: "https://wss.bitwiz.me/",
        repo: "https://github.com/prasoonsoni/WSS-WannaSaySomething",
        emoji: "💑"
    },
    {
        id: 2,
        title: "FFCSeZ",
        subtitle: "An app to eaZe your FFCS.",
        link: "https://play.google.com/store/apps/details?id=com.tfd.ffcsez",
        repo: "https://github.com/Team-Fourth-Dimension/FFCSeZ",
        emoji: "🗓️"
    },
    {
        id: 3,
        title: "Social Media Backend",
        subtitle: "Backend for a social media app or a website.",
        link: "",
        repo: "https://github.com/prasoonsoni/Social-Media-Backend",
        emoji: "🚀"
    },
    {
        id: 4,
        title: "Safe Chain",
        subtitle: "Secure, Decentralized Access to Your Data.",
        link: "",
        repo: "https://github.com/prasoonsoni/Safe-Chain-Backend",
        emoji: "🔐"
    },
    {
        id: 5,
        title: "Drive Hub",
        subtitle: "Connecting your files, everywhere you go.",
        link: "https://drivehub.bitwiz.me",
        repo: "https://github.com/prasoonsoni/DriveHub",
        emoji: "📦"
    },
    {
        id: 6,
        title: "Linktory",
        subtitle: "Just share one link that contains all your links.",
        link: "https://linktory.bitwiz.me",
        repo: "https://github.com/prasoonsoni/Linktory-Frontend-2.0",
        emoji: "📌"
    },
    {
        id: 7,
        title: "Pastebin",
        subtitle: "Sharing short code samples, logs or links is now easier than ever!",
        link: "https://pastebin.bitwiz.me",
        repo: "https://github.com/prasoonsoni/Pastebin",
        emoji: "🗃️"
    },
    {
        id: 9,
        title: "react-chakra-starter",
        subtitle: "Boilerplate for a React.js + Chakra-UI project.",
        link: "",
        repo: "https://github.com/prasoonsoni/react-chakra-starter",
        emoji: <Text color="#5ED3F3"><SiReact /></Text>
    },
    {
        id: 10,
        title: "node-express-starter",
        subtitle: "Boilerplate for a Node.js + Express.js project.",
        link: "",
        repo: "https://github.com/prasoonsoni/node-express-starter",
        emoji: <Text color="#60B148"><SiNodedotjs /></Text>
    },
    {
        id: 11,
        title: "express-ts-starter",
        subtitle: "Boilerplate for a Node.js + Express + TypeScript project.",
        link: "",
        repo: "https://github.com/prasoonsoni/express-ts-starter",
        emoji: <Text color="#2F74C0"><SiTypescript /></Text>
    }
]