import { Stack, useColorMode } from '@chakra-ui/react';
import React, { useCallback, useContext } from 'react';
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import backgroundContext from '../context/backgroundContext';
const Background = () => {
    const particlesInit = useCallback(async (engine) => {
        console.log(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {
        await console.log(container);
    }, []);
    const { colorMode } = useColorMode()
    const context = useContext(backgroundContext)
    const { showBackground } = context
    return (
        <Stack>
            {showBackground && <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={{
                    fpsLimit: 120,
                    particles: {
                        color: {
                            value: colorMode === "dark" ? "#939595" : "#848484",
                        },
                        links: {
                            color: colorMode === "dark" ? "#939595" : "#848484",
                            distance: 150,
                            enable: false,
                            opacity: 0.5,
                            width: 1,
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 1,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 800,
                            },
                            value: 15,
                        },
                        opacity: {
                            value: 0.5,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            value: { min: 1, max: 5 },
                        },
                    },
                    detectRetina: true,
                }}
            />}
        </Stack>
    )
}

export default Background